<div class="container-fluid bg-dark" id="topMenu">
  <div class="container">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark p-0">
      <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
        <a href="" class="text-white"> </a>
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="collapsingNavbar">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link bg-transparent text-white" routerLink="contact" style="cursor: default;">
              <i class="far fa-envelope"></i> iv@cprandfirstaidtraininginstitute.com </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link bg-transparent text-white" routerLink="contact" style="cursor:default;">
              <i class="fas fa-phone-volume text-white"></i> + 1 (405) 766-0278</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>

<!--Menú Logo-->
<div class="container-fluid " style="background-image: url('./assets/img/bg1.jpg');" id="headerinformation">
  <div class="container">
    <div class="row p-1">
      <div class="col-md-3 text-center">
        <div>
          <a routerLink=""> <img id="logoMenu" class="logo mx-auto pt-5" style="width:150px;" src="./assets/img/logo.png"><b
              style="font-weight:bold; color:black; font-size:10px; position:relative; top:70px; left:-22px;">TM</b></a>
        </div>
      </div>
      <div class="col-md-6 text-center pt-1">
        <p class="websiteTitle" style="font-family: 'Francois One', sans-serif; font-size:1.7em; color:#006699;">CPR &
          FIRST AID TRAINING INSTITUTE
          <b class="tmTitle"
            style="color:black; font-size: 10px !important; position:relative;top:10px !important; margin-left:-27px !important; font-size:10px !important;">TM</b>
        </p>

        <p id="websiteSlogan"
          style="font-family: 'Seaweed Script', cursive; font-size:28px; color:#B22222; margin-top:-23px;">"Teaching
          you to save lives"
          <b id="tmSlogan" style="font-family: 'Francois One', sans-serif; font-size:10px; color:black;position:relative;
  top:10px !important;
  margin-left:-25px !important;
  text-align:center !important;	">TM</b>
        </p>
        <div class="mx-auto" style="max-width: 460px; margin-top:35px;">
          <p class="text-justify"
            style="text-align: justify !important; max-width:460px; font-size:15px; margin-top:-35px; color:#006699;">
            CPR & First Aid Training Institute is a statewide CPR & First
            Aid training institute delivering high quality, hands-on training to daycare providers, construction and
            manufacturing industries in Oklahoma. We
            serve the Oklahoma City and Tulsa metropolitan
            areas, including multiple locations across the State
            of Oklahoma.
          </p>
          <a href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false"><button id="btnRegister" type="button"
              class="btn btn-primary text-uppercase p-4" style="display:none;">Register now for a training
              class</button></a>
          <button id="btnRegisterA" type="button" class="btn btn-primary text-uppercase p-4" data-toggle="modal"
            data-target="#modalassociation" style="display:none;"><b class="h2">OAPA</b><br> training classes</button>
        </div>

      </div>
      <div class="col-md-3 text-center pb-2">
        <p _ngcontent-wps-c29="" style="margin-top: 50px; color: #ad1e4a; font-size: 19px; font-family: 'Roboto', sans-serif;"><span _ngcontent-wps-c29="" style="letter-spacing: 5px; width: 900px; font-weight: bold; font-size: 30px;">CECPD</span><br _ngcontent-wps-c29="">Approved Training Classes</p>
        <b class="BCA nav-link text-dark text-uppercase mb-4" href="contact" style="font-size:13px;"> SPANISH
          CLASSES AVAILABLE!</b>
      </div>
    </div>
  </div>
</div>
<!-- Desktop -->
<div class="container-fluid MenuDesktop py-2 mt-4" style="background: #1f8cc2;">
  <div class="container">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <a class="nav-link text-white" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="fas fa-address-card"></i> Certificate Replacement</a>
      </li>
      <li class="nav-item">
        <a href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false/#/home/search?type=NF&month=1&location=NF&spanish=false"><button id="btnCovid19" type="button" class=" px-2 btn btn-primary"
          style="background:#1f8cc2 !important; position:absolute; margin-left:-0px; margin-top:-40px; height: 40px; width:255px; font-size:13px;"> Register For Spanish Training Classes</button></a>
        <a href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false" target="_blank">
          <button id="registerButtonMenu" type="button" class=" px-2 btn btn-danger py-2"
            style="background:#B22222 !important; width:255px;font-size: 15px; padding-top:6px;padding-bottom:6px;">
            Register Now For A Training Class
          </button>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-white" routerLink=""><i class="fas fa-home text-white"></i> Home</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button"
          data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fas fa-briefcase-medical text-white"></i> Training Classes Offered
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item" routerLink="osha">OSHA Safety Training</a></li>
          <!--<li><a class="dropdown-item" routerLink="respiratory">Respiratory Training</a></li>-->
          <li><a class="dropdown-item" routerLink="bloodborne">BloodBorne Pathogens</a></li>
          <li><a class="dropdown-item" routerLink="forklift">Fork Lift Training</a></li>
          <li><a class="dropdown-item" routerLink="cpr">CPR & First Aid</a></li>
          <!--<li><a class="dropdown-item" routerLink="disease">Disease Management Prevention & Education</a></li>-->
        </ul>
      </li>
      <li class="nav-item">
        <a class="nav-link text-white" routerLink="contact"><i class="fas fa-envelope text-white"></i> Contact Us</a>
      </li>
    </ul>
  </div>
</div>
<!-- /Desktop -->
<!-- Mobil -->
<div class="container-fluid MenuMobil py-2" style="background: #1f8cc2;">
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
    <div class="container-fluid">
      <a class="navbar-brand" href="" style="color:white;">Home</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item px-2">
            <a id="btnCovid19" class="nav-link"  style="color:white;"
              href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false">Register Now For A Training Class</a>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false" style="color:white;">
              Training Classes Offered
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" routerLink="osha">OSHA Safety Training</a></li>
              <!--<li><a class="dropdown-item" routerLink="respiratory">Respiratory Training</a></li>-->
              <li><a class="dropdown-item" routerLink="bloodborne">BloodBorne Pathogens</a></li>
              <li><a class="dropdown-item" routerLink="forklift">Bilingual Fork Lift Training</a></li>
              <li><a class="dropdown-item" routerLink="cpr">CPR & First Aid</a></li>
              <!--<li><a class="dropdown-item" routerLink="disease">Disease Management Prevention & Education</a></li>-->

            </ul>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link" routerLink="contact" style="color:white;">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<!-- /Mobil -->
<!-- Modal Covid 19 guidance-->
<div class="modal fade" id="covid19" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">COVID-19 Guidance</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <img src="./assets/img/Pediatric Ceritificate Reminder.png" alt="" width="100%">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal Association -->
<div class="modal fade" id="association" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Enter Your Association Id</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <div class="alert alert-danger" role="alert" *ngIf="RespuestaAsociacion">
            {{EstatusAsociacion}}
          </div>
          <input type="text" class="form-control" [(ngModel)]="Association" id="exampleInputEmail1" name="code"
            aria-describedby="emailHelp" placeholder="Association id...">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="VerificarAssociation()">Go</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Replacement Certificate </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Please fill out the following information to began the process.</p>
              <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Your full name</label>
                  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder=""
                      [(ngModel)]="name" name="name">
              </div>
              <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Your email</label>
                  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder=""
                      [(ngModel)]="email" name="k">
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-primary" (click)="GoToCertificatePayment()">Submit</button>
          </div>
      </div>
  </div>
</div>